module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Lewd Dudes | Alternative Rock Band | Austin Texas","short_name":"The Lewd Dudes","start_url":"/","background_color":"#51dd97","theme_color":"#51dd97","display":"minimal-ui","icon":"src/images/favicon-tld-epk.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a7a7c96442854bc9bb7423b437050a03"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
